import css from './hub/tabs.module.scss';

interface Props {
  panels: string[];
  color: string;
  activeTab: string | number;
  setActiveTab: (label) => void;
}

export const Panels = (props: Props) => {
  const { panels = [], setActiveTab, color = 'primary', activeTab } = props;
  const tabStyle: Record<string, number> = {
    '--tab-count': panels.length,
  };

  return (
    <div className={css.tab} color={color}>
      {panels.map(panel => (
        <hub-tab
          key={panel}
          className={css.tabHeader}
          style={tabStyle}
          active={activeTab === panel}
          font="label-large"
          onClick={() => setActiveTab(panel)}
        >
          {panel}
        </hub-tab>
      ))}
    </div>
  );
};
