import { GridColumn } from './GridColumn';
import { css } from './hub';

interface Props {
  children: React.ReactNode;
  columns?: number;
  align?: string;
  justify?: string;
  wrap?: string;
  color?: string;
  Col?: React.FC;
}

export const Grid = (props: Props) => {
  const { children, align, justify, wrap, columns = 12, color } = props;
  const gridStyle: Record<string, number | string> = {
    '--grid-col': columns,
    '--align': align,
    '--justify': justify,
    '--wrap': wrap,
  };
  return (
    <div className={css.grid} style={gridStyle} color={color}>
      {children}
    </div>
  );
};

Grid.Col = GridColumn;
