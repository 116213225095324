import { CardContent } from './CardContent';
import { CardFooter } from './CardFooter';
import { CardHeader } from './CardHeader';
import { CardImage } from './CardImage';
import { useCard } from './hub';

interface Props {
  children: React.ReactNode;
  color: string;
  cover: boolean;
  Header: React.FC;
  Content: React.FC;
  Footer: React.FC;
  Image: React.FC;
}

export const Card = (props: Props) => {
  const { children, color = 'surface-f', cover } = props;
  const { css } = useCard(props);
  return (
    <hub-card className={css.cardRoot} color={color} cover={cover}>
      {children}
    </hub-card>
  );
};

Card.Header = CardHeader;
Card.Content = CardContent;
Card.Footer = CardFooter;
Card.Image = CardImage;
