import { useEffect, useState } from 'react';
import { useDialog } from './hub';
import { Content } from './hub/Content';
import { Footer } from './hub/Footer';
import { Header } from './hub/Header';
import css from './hub/dialog.module.scss';

interface Props {
  children: React.ReactNode;
  name: string;
  isOpen: boolean;
  direction?: string;
  Header?: React.FC;
  Content?: React.FC;
  Footer?: React.FC;
}

export const Dialog = (props: Props) => {
  const { name, children } = props;
  const dialog = useDialog(name);
  const { show } = dialog;
  const [status, setStatus] = useState('closed');
  useEffect(() => {
    // eslint-disable-next-line no-undefined
    if (show !== undefined) {
      setStatus('animate');
      setTimeout(() => {
        setStatus(() => {
          return show ? 'open' : 'closed';
        });
      }, 250);
    }
  }, [show]);

  return (
    <hub-dialog status={status}>
      <aside className={css.content} color="surface-f">
        {children}
      </aside>
      <div className={css.backdrop} onClick={dialog.close} />
    </hub-dialog>
  );
};

Dialog.Header = Header;
Dialog.Content = Content;
Dialog.Footer = Footer;
