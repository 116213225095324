import { icons } from './hub/icons';
import './hub/icon.scss';

interface Props {
  children: React.ReactNode;
  size?: string;
}

export const Icon = (props: Props) => {
  const { children, size } = props;

  return (
    <hub-icon size={size}>
      <img src={icons[children]} />
    </hub-icon>
  );
};
