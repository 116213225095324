import css from './sidebar.module.scss';

export const useSidebar = ({}) => {
  const sidebarStyle: Record<string, string> = {
    '--width-xs': '100%',
    '--width-sm': '100%',
    '--width-md': '20%',
    '--width-lg': '20%',
  };
  return { css, sidebarStyle };
};
