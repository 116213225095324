import { DialogContext } from '../../Dialog';
import { ToastContext } from '../../Toast';
import { ThemeProvider, useTheme } from '../ThemeProvider';
interface Props {
  name: string;
  children: React.ReactNode;
  theme?: string;
}

export const Hub = ({ name, theme, children }: Props) => {
  const hubTheme = useTheme()?.theme || theme;

  return (
    <hub-wrap>
      <ThemeProvider theme={hubTheme}>
        <ToastContext name={`${name}_toast`}>
          <DialogContext>{children}</DialogContext>
        </ToastContext>
      </ThemeProvider>
    </hub-wrap>
  );
};
