import { Button } from '@clutch/part/Button';
import { Card } from '@clutch/part/Card';
import { Flex } from '@clutch/part/Flex';
import { Icon } from '@clutch/part/Icon';

export const AppLayout = ({ config = {} }) => {
  return (
    <Card>
      <Card.Header>{config.name}</Card.Header>
      <Card.Content>{config.description}</Card.Content>
      <Card.Footer>
        <Flex align="center" justify="flex-end">
          <Button
            color="tertiary-f"
            onClick={() => {
              window.location.hash = config.href;
            }}
          >
            <Icon>launch</Icon>
          </Button>
        </Flex>
      </Card.Footer>
    </Card>
  );
};
