import { useMemo } from 'react';
type BreakPoint = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
};

export const useGrid = span => {
  const data = useMemo(() => {
    const gridSpan: BreakPoint =
      typeof span === 'number'
        ? { xs: span, sm: span, md: span, lg: span }
        : span;
    const colStyle: Record<string, number> = {};

    const dataProps = {};
    Object.keys(gridSpan).forEach(bp => {
      if (gridSpan[bp]) {
        colStyle[`--span-${bp}`] = gridSpan[bp];
      } else {
        dataProps[`data-hidden-${bp}`] = true;
      }
    });
    return { colStyle, dataProps };
  }, []);
  return data;
};
