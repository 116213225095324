import { createContext, useContext, useState } from 'react';
import { Toast } from '../Toast';

type ToastProps = {
  title?: string;
  message?: string;
  variant?: string;
};
interface ContextProps {
  toast: ToastProps;
  setToast?: (toastId) => void;
}
export const ToastCtxt = createContext<ContextProps>({} as ContextProps);

export const ToastContext = ({ name, children }) => {
  const [toast, setToast] = useState({});

  return (
    <ToastCtxt value={{ toast, setToast }}>
      <Toast name={name} />
      {children}
    </ToastCtxt>
  );
};

export const useToast = () => {
  const context = useContext(ToastCtxt);

  return {
    show: context.toast?.message,
    data: context.toast || {},
    open: toastProps => context.setToast(toastProps),
    reset: () => context.setToast({}),
    variant: context.toast?.variant,
  };
};
