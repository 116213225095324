import { Grid } from '@clutch/part/Grid';
import { Image } from '@clutch/part/Image';
import { Sidebar } from '@clutch/part/Sidebar';
import { Tabs } from '@clutch/part/Tabs';
import { AppLayout } from './AppLayout';
import { apps } from '../resources/apps';
import { games } from '../resources/games';

export const Dashboard = () => {
  return (
    <Sidebar>
      <Sidebar.Header logo={<Image src="/logo/1page.svg" type="logo" />} />
      <Sidebar.Menu
        content={
          <div>
            <Tabs>
              <Tabs.Tab label="Apps">
                <Grid>
                  <For each="config" of={apps}>
                    <Grid.Col
                      key={config.name}
                      span={{ xs: 12, sm: 12, md: 6, lg: 6 }}
                    >
                      <AppLayout config={config} />
                    </Grid.Col>
                  </For>
                </Grid>
              </Tabs.Tab>
              <Tabs.Tab label="Games">
                <Grid>
                  <For each="config" of={games}>
                    <Grid.Col
                      key={config.name}
                      span={{ xs: 12, sm: 12, md: 6, lg: 6 }}
                    >
                      <AppLayout config={config} />
                    </Grid.Col>
                  </For>
                </Grid>
              </Tabs.Tab>
            </Tabs>
          </div>
        }
      >
        <Sidebar.MenuRoute href="#/clutch/about">About</Sidebar.MenuRoute>
        <Sidebar.MenuRoute href="#/clutch/credits">Credits</Sidebar.MenuRoute>
      </Sidebar.Menu>
    </Sidebar>
  );
};
