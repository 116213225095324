import { cloneElement, Fragment, Suspense, useCallback } from 'react';
import { Routes as DomRoutes, Route } from 'react-router-dom';

interface Props {
  routes: {}[];
  cloneProps?: {};
}
export const Routes = ({ routes, cloneProps }: Props) => {
  const getRoutes = useCallback(data => {
    return data.map(route => {
      if (route.data) {
        return getRoutes(route.data);
      }
      return (
        <Fragment key={route.path}>
          <Route
            element={
              <Suspense fallback={<h4>Loading...</h4>}>
                {cloneElement(route.element, cloneProps)}
              </Suspense>
            }
            path={route.path}
          />
        </Fragment>
      );
    });
  }, []);

  return <DomRoutes>{getRoutes(routes)}</DomRoutes>;
};
