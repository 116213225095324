import { css, useFlex } from './hub';

interface Props {
  children: React.ReactNode;
  align?: string;
  justify?: string;
  direction?: string;
}

export const Flex = (props: Props) => {
  const { children } = props;
  const flex = useFlex(props);
  return (
    <div className={css.flex} style={flex.flexStyle}>
      {children}
    </div>
  );
};
