import { Children, useMemo, useState } from 'react';
import { useTabs } from './hub';
import { Panels } from './Panels';
import { Tab } from './Tab';

interface Props {
  children: JSX.Element;
  panelColor?: string;
  contentColor?: string;
  Tab: React.FC;
  defaultTab: string | number;
}

type TabsList = {
  panels: string[];
  tab: JSX.Element;
};

export const Tabs = (props: Props) => {
  const { children, defaultTab, panelColor, contentColor } = props;
  const [activeTab, setActiveTab] = useState(defaultTab);
  const { css } = useTabs(props);

  const tabsList: TabsList = useMemo(() => {
    let tab;
    const panels = Children.map(children, child => {
      if (activeTab === child.props.label) {
        tab = child;
      }
      return child.props.label;
    });
    if (!tab) {
      setActiveTab(children[0].props.label);
    }
    return { panels, tab };
  }, [activeTab]);

  return (
    <div className={css.tabsRoot}>
      <Panels
        panels={tabsList.panels}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        color={panelColor}
      />
      <Tab color={contentColor}>{tabsList.tab}</Tab>
    </div>
  );
};

Tabs.Tab = Tab;
