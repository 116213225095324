export const useFlex = ({
  align = 'initial',
  justify = 'initial',
  direction = 'row',
}) => {
  const flexStyle: Record<string, string> = {
    '--align': align,
    '--justify': justify,
    '--direction': direction,
  };
  return {
    flexStyle,
  };
};
