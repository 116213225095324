import { css, useGrid } from './hub';

type BreakPoint = {
  xs: number | string;
  sm: number | string;
  md: number | string;
  lg: number | string;
};

interface Props {
  children: React.ReactNode;
  span: number | string | BreakPoint;
}

export const GridColumn = (props: Props) => {
  const { children, span } = props;
  const grid = useGrid(span);
  return (
    <div className={css.gridCol} style={grid.colStyle} {...grid.dataProps}>
      {children}
    </div>
  );
};
