import { useButton } from './hub';

interface Props {
  children: React.ReactNode;
  color?: string;
  type?: 'button' | 'submit' | 'reset';
  shape?: string;
  size?: string;
  full?: boolean;
  hidden?: boolean;
  onClick?: (e) => void;
  disabled?: boolean;
}

export const Button = (props: Props) => {
  const {
    children,
    color = 'primary',
    hidden,
    shape,
    size,
    type = 'button',
    disabled,
    full,
  } = props;
  const { buttonProps } = useButton(props);
  return (
    <hub-button
      hidden={hidden}
      color={disabled ? 'disabled' : color}
      shape={shape}
      size={size}
      type={type}
      disabled={disabled}
      full={full}
      {...buttonProps}
    >
      {children}
    </hub-button>
  );
};
