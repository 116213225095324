import { useEffect, useState } from 'react';
import { useDrawer } from './hub';
import { Button } from '../Button';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  direction?: string;
  onClose?: () => void;
}

export const Drawer = (props: Props) => {
  const { children, isOpen, onClose = () => null } = props;
  const [status, setStatus] = useState('closed');
  const { css } = useDrawer(props);
  useEffect(() => {
    setStatus('animate');
    setTimeout(() => {
      setStatus(() => {
        return isOpen ? 'open' : 'closed';
      });
    }, 250);
  }, [isOpen]);

  return (
    <hub-drawer status={status}>
      <aside className={css.content} color="primary-f">
        <div className={css.menuIcon}>
          <Button onClick={onClose}>Home</Button>
        </div>
        <div className={css.menuContent}>{children}</div>
      </aside>
      <div className={css.backdrop} onClick={onClose} />
    </hub-drawer>
  );
};
