import './hub/image.module.scss';

interface Props {
  src: string;
  alt?: string;
  type: string;
  onClick?: () => void;
}

export const Image = (props: Props) => {
  const { src, alt, type, onClick } = props;
  return <img src={src} alt={alt} data-type={type} onClick={onClick} />;
};
