import { lazy } from 'react';
const Clutch = lazy(() => import('@spoke/App'));
const JSONManager = lazy(() => import('@apps/jsonmanager/App'));
const Decoders = lazy(() => import('@apps/decoders/App'));
const PointingPoker = lazy(() => import('../../../apps/pointingpoker/src/App'));

export const apps = [
  {
    name: 'Clutch',
    element: <Clutch />,
    description: 'ReactJS Components',
    path: 'clutch/*',
    href: '/clutch',
    img: 'clutch_card.jpg',
  },
  {
    name: 'JSON Manager',
    element: <JSONManager />,
    description: 'JSON Manager',
    path: 'jsonmanager/*',
    href: '/jsonmanager',
    img: 'quasar_card.jpg',
  },
  {
    name: 'Decoders',
    element: <Decoders />,
    description: 'Decoders',
    path: 'decoders/*',
    href: '/decoders',
    img: 'json_card.jpg',
  },
  {
    name: 'Pointing Poker',
    element: <PointingPoker />,
    description: 'Pointing Poker',
    path: 'pointingpoker/*',
    href: '/pointingpoker',
    img: 'json_card.jpg',
  },
];
