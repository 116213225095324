import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSidebar } from './hub';
import { SidebarContext } from './SidebarContext';
import { SidebarDivider } from './SidebarDivider';
import { SidebarHeader } from './SidebarHeader';
import { SidebarLogo } from './SidebarLogo';
import { SidebarMenu } from './SidebarMenu';
import { SidebarRoute } from './SidebarRoute';

interface Props {
  children: React.ReactNode;
  SidebarHeader?: React.FC;
  SidebarLogo?: React.FC;
  SidebarMenu?: React.FC;
  SidebarRoute?: React.FC;
  SidebarDivider?: React.FC;
}

export const Sidebar = (props: Props) => {
  const { children } = props;
  const [isMenuOpen, toggleMenu] = useState(false);
  const { css, sidebarStyle } = useSidebar(props);
  const location = useLocation();

  const onToggle = () => {
    toggleMenu(isOpen => !isOpen);
  };

  return (
    <SidebarContext
      value={{ isMenuOpen, onToggle, location: location.pathname }}
    >
      <div className={css.root} style={sidebarStyle}>
        {children}
      </div>
    </SidebarContext>
  );
};

Sidebar.Header = SidebarHeader;
Sidebar.Logo = SidebarLogo;
Sidebar.Menu = SidebarMenu;
Sidebar.MenuRoute = SidebarRoute;
Sidebar.Divider = SidebarDivider;
