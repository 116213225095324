import { useEffect } from 'react';
import { useToast } from './hub/useToast';
import { Button } from '../Button';
import { Icon } from '../Icon';
import css from './hub/toast.module.scss';

let timeOutId;

interface Props {
  name: string;
}

export const Toast = ({ name }: Props) => {
  const toast = useToast();
  const { show, data, reset, variant } = toast;
  const resetTimeOut = () => {
    clearTimeout(timeOutId);
  };

  useEffect(() => {
    if (data.title || data.message) {
      timeOutId = setTimeout(() => {
        reset();
      }, 4000);
      return resetTimeOut;
    }
    return () => null;
  }, [data.title, data.message]);

  const handleClose = () => {
    resetTimeOut();
    reset();
  };

  if (!show) {
    return null;
  }
  return (
    <hub-toast color={variant}>
      <div className={css.toast}>
        {data.title && <div className={css.title}>{data.title}</div>}
        <div className={css.close}>
          <Button size="xs" color="tertiary-f" onClick={handleClose}>
            <Icon size="xs">close</Icon>
          </Button>
        </div>
      </div>
      {data.message && <div className={css.message}>{data.message}</div>}
    </hub-toast>
  );
};
