import { Hub } from '@clutch/part/providers/Hub';
import { Routes } from '@clutch/part/Routes';
import { Dashboard } from './Dashboard';
import { apps } from './resources/apps';
import { games } from './resources/games';

const dashboard = {
  element: <Dashboard />,
  path: '',
};

const routes = [dashboard, ...apps, ...games];
const App = () => {
  return (
    <Hub theme="light">
      <Routes routes={routes} />
    </Hub>
  );
};

export default App;
