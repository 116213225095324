import css from './hub/sidebar.module.scss';

interface Props {
  children: React.ReactNode;
  hash: string;
}

export const SidebarLogo = (props: Props) => {
  const { children, hash } = props;

  return (
    <div
      className={css.logo}
      onClick={() => {
        window.location.hash = hash;
      }}
    >
      {children}
    </div>
  );
};
