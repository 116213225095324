import React from 'react';

type MyProps = {
  children: React.ReactNode;
};
type MyState = {
  hasError: boolean;
  error?: string;
};

export class ErrorBoundary extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.message };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { hasError, error } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return <h1>{error}</h1>;
    }
    const { children } = this.props;

    return children;
  }
}
