/* eslint-disable camelcase */
import close from './close.svg';
import copy from './copy.svg';
import down from './down.svg';
import download from './download.svg';
import filter from './filter.svg';
import full_screen from './full_screen.svg';
import full_screen_exit from './full_screen_exit.svg';
import launch from './launch.svg';
import left from './left.svg';
import menu from './menu.svg';
import plow from './plow.svg';
import right from './right.svg';
import up from './up.svg';

export const icons = {
  close,
  copy,
  download,
  filter,
  full_screen,
  full_screen_exit,
  launch,
  menu,
  plow,
  down,
  up,
  left,
  right,
};
