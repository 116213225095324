import { createContext, useContext, useState } from 'react';
import '../../../styles/hub.scss';

interface Props {
  children: React.ReactNode;
  theme: string;
}

interface ThemeProps {
  theme?: string;
  setHubTheme?: (theme) => void;
}

const ThemeContext = createContext<ThemeProps>({});
export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider = ({ theme, children }: Props) => {
  const [hubTheme, setHubTheme] = useState(theme);
  return (
    <ThemeContext value={{ theme: hubTheme, setHubTheme }}>
      <hub-theme theme={hubTheme}>{children}</hub-theme>
    </ThemeContext>
  );
};
