import { createContext, useContext, useState } from 'react';

interface ContextProps {
  dialog?: {};
  setDialog?: (name) => void;
}
export const DialogCtxt = createContext<ContextProps>({} as ContextProps);

export const DialogContext = ({ children }) => {
  const [dialog, setDialog] = useState({});

  return <DialogCtxt value={{ dialog, setDialog }}>{children}</DialogCtxt>;
};

export const useDialog = name => {
  const context = useContext(DialogCtxt);
  return {
    show: context.dialog[name]?.show,
    data: context.dialog[name]?.data,
    open: (data = {}) =>
      context.setDialog(prevState => ({
        ...prevState,
        [name]: { show: true, data },
      })),
    close: () =>
      context.setDialog(prevState => ({
        ...prevState,
        [name]: { show: false, data: {} },
      })),
  };
};
