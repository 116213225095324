export const games = [
  {
    name: 'Slinger',
    element: <div>Slinger</div>,
    description: 'Slinger',
    path: 'slinger/*',
    href: '/slinger',
    img: 'clutch_card.jpg',
  },
];
