import { useContext } from 'react';
import { SidebarContext } from './SidebarContext';
import css from './hub/sidebar.module.scss';

interface Props {
  children: React.ReactNode;
  href: string;
}

export const SidebarRoute = (props: Props) => {
  const { children, href } = props;
  const context = useContext(SidebarContext);

  return (
    <a
      href={href}
      className={css.menuRoute}
      data-active={href === `#${context.location}`}
      onClick={context.onToggle}
    >
      {children}
    </a>
  );
};
