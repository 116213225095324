import { createContext } from 'react';

type SidebarContextType = {
  isMenuOpen?: boolean;
  onToggle?: () => void;
  location?: string;
};

const SidebarContext = createContext<SidebarContextType>({});

export { SidebarContext };
